var SearchFilterStore = function (organizations, programs, selectedProgramId, selectedOrganizationId) {
  this.selectedProgramId = selectedProgramId || ''
  this.selectedOrganizationId = selectedOrganizationId || 0
  this.organizations = organizations || []
  this.programs = programs || []
};

SearchFilterStore.prototype = {
  currentPrograms: function() {
    if (this.selectedOrganizationId > 0) {
      return this.programs.filter(p => p.organization_id === this.selectedOrganizationId);
    } else {
      return this.programs;
    }
  },
  resetSelectedProgramId: function() {
    this.selectedProgramId = 0;
  }
}

export default function({ organizations, programs, selectedProgramId, selectedOrganizationId }) {
  return new SearchFilterStore(organizations, programs, selectedProgramId, selectedOrganizationId)
};
