import axios from 'axios'
import VueVersionComponent from '../../javascripts/components/vue_version.vue'
import ExperienceDetailsComponent from '../../javascripts/components/experiences/experience_details.vue'
import ExperienceInstanceRowComponent from '../../javascripts/components/experiences/experience_instance_row.vue'
import ExperienceRowComponent from '../../javascripts/components/experiences/experience_row.vue'
import ExperienceListComponent from '../../javascripts/components/experiences/experience_list.vue'
import CollectionSelectComponent from '../../javascripts/components/shared/collection_select.vue'
import createSearchFilterStore from '../../javascripts/components/shared/search_filter_store.js'
import DropdownButtonComponent from '../../javascripts/components/dropdown_button.vue'
import EmailAttachmentsPreviewComponent from '../../javascripts/components/email_attachments_preview.vue'
import EmailAttachmentsWidgetComponent from '../../javascripts/components/email_attachments_widget.vue'
import MultiSelectCheckboxComponent from '../../javascripts/components/shared/multi_select_checkbox.vue'
import ErrorExplanationComponent from '../../javascripts/components/shared/error_explanation.vue'
import InterviewSlotPickerComponent from '../../javascripts/components/interviews/interview_slot_picker.vue'
import MultiInterviewFormFieldComponent from '../../javascripts/components/interviews/multi_interview_form_field.vue'
import MultiInterviewFormComponent from '../../javascripts/components/interviews/multi_interview_form.vue'
import LocationSelectComponent from '../../javascripts/components/interviews/location_select.vue'
import MultiInterviewPageComponent from '../../javascripts/components/interviews/multi_interview_page.vue'

window.Vue = Vue
window.axios = axios
window.VueVersionComponent = VueVersionComponent
window.ExperienceDetailsComponent = ExperienceDetailsComponent
window.ExperienceInstanceRowComponent = ExperienceInstanceRowComponent
window.ExperienceRowComponent = ExperienceRowComponent
window.ExperienceListComponent = ExperienceListComponent
window.CollectionSelectComponent = CollectionSelectComponent
window.DropdownButtonComponent = DropdownButtonComponent
window.EmailAttachmentsPreviewComponent = EmailAttachmentsPreviewComponent
window.EmailAttachmentsWidgetComponent = EmailAttachmentsWidgetComponent
window.MultiSelectCheckboxComponent = MultiSelectCheckboxComponent
window.ErrorExplanationComponent = ErrorExplanationComponent
window.InterviewSlotPickerComponent = InterviewSlotPickerComponent
window.MultiInterviewFormFieldComponent = MultiInterviewFormFieldComponent
window.MultiInterviewFormComponent = MultiInterviewFormComponent
window.LocationSelectComponent = LocationSelectComponent
window.MultiInterviewPageComponent = MultiInterviewPageComponent

var vm = Vue.createApp({})
vm.config.compilerOptions.whitespace = "preserve"
vm.component('VueVersion', VueVersionComponent)
vm.mount('#build-info-app');

window.createEmailAttachmentsApp = function() {
  window.emailAttachmentsApp = Vue.createApp({
    mounted() {
      window.emailAttachmentsWidget = this.$refs.emailAttachmentsWidget
    }
  })
  window.emailAttachmentsApp.config.compilerOptions.whitespace = "preserve"
  window.emailAttachmentsApp.component('EmailAttachmentsPreview', EmailAttachmentsPreviewComponent)
  window.emailAttachmentsApp.component('EmailAttachmentsWidget', EmailAttachmentsWidgetComponent)
  window.emailAttachmentsApp.mount('#email-attachments')
}

window.createExperienceApp = function(expAppData) {
  var expApp = Vue.createApp({ data() { return expAppData } });
  expApp.config.compilerOptions.whitespace = "preserve"
  expApp.component('experience-details', ExperienceDetailsComponent)
  expApp.component('experience-instance-row', ExperienceInstanceRowComponent)
  expApp.component('experience-row', ExperienceRowComponent)
  expApp.component('experience-list', ExperienceListComponent)
  expApp.mount('#experiences_panel')
}

window.createMultiInterviewApp = function() {
  var multiInterviewApp = Vue.createApp({})
  multiInterviewApp.config.compilerOptions.whitespace = "preserve"
  multiInterviewApp.config.globalProperties.$http = axios
  multiInterviewApp.component('multi-select-checkbox', MultiSelectCheckboxComponent)
  multiInterviewApp.component('error-explanation', ErrorExplanationComponent)
  multiInterviewApp.component('location-select', LocationSelectComponent)
  multiInterviewApp.component('multi-interview-form-field', MultiInterviewFormFieldComponent)
  multiInterviewApp.component('interview-slot-picker', InterviewSlotPickerComponent)
  multiInterviewApp.component('multi-interview-form', MultiInterviewFormComponent)
  multiInterviewApp.component('multi-interview-page', MultiInterviewPageComponent)
  multiInterviewApp.mount('#page')
}

window.createFieldFilterApp = function(searchFilterStore, container) {
  var ffApp = Vue.createApp({
    data() {
      return { store: searchFilterStore }
    },
    mounted() {
      $(this.$el).find(".datepicker").removeClass('hasDatepicker').datepicker();
    }
  });
  ffApp.provide('store', searchFilterStore)
  ffApp.config.globalProperties.$store = searchFilterStore
  ffApp.config.compilerOptions.whitespace = "preserve"
  ffApp.component('collection-select', CollectionSelectComponent)
  ffApp.mount(container)
}

window.createSearchFilterStore = createSearchFilterStore

window.preventFormSubmissionDuringAttachmentProcessing = function (selectors) {
  $(selectors).on('submit', function(e) {
    if(emailAttachmentsWidget.isProcessingFiles()) {
      alert("This action can't be performed while attachments are being added.");
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  });
}

console.log('vue-loaded')
const event = new Event('vue-loaded');
document.dispatchEvent(event)
