<script>
export default {
  name: 'multi-interview-form',
  props: {
    source: {
      type: String,
      required: true
    },
    multiInterview: {
      type: Object,
      default: () => ({})
    },
    name: String,
    allTypes: {
      type: Array,
      default: () => ([])
    },
    selectableTypeIds: {
      type: Array
    },
    modelValue: {
      type: Object,
      default: () => ({ interviewTypes: [], interviewers: [] })
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      types: [],
      applicantIds: this.multiInterview.applicant_ids || [],
      selectedTypeIds: this.multiInterview.type_ids || [],
      selectedSlot: this.multiInterview.slot,
      selectedLocation: this.multiInterview.location,
      selectedInterviewerIds: this.multiInterview.interviewer_ids || [],
      selectedProgramIds: this.multiInterview.program_ids || []
    };
  },
  methods: {
    dateAndTimeFor(slot) {
      return slot.date + ' ' + slot.time;
    },
    fetchTypes() {
      this.$http.get(this.source)
        .then(response => response.data)
        .then(data => this.types = data);
    },
    sort(array) {
      return array.sort((a, b) => a.name.localeCompare(b.name));
    },
    intersection(array) {
      let result = [];
      if (array.length !== 0 && array[0] !== undefined) {
        result = array.reduce((a, b) => a.filter(c => b.map(i => i.id).includes(c.id)));
      }
      return result;
    },
    unique(array) {
      let result = [];
      if (array.length !== 0 && array[0] !== undefined) {
        result = array.filter((v, i, a) => a.map(ui => ui.id).indexOf(v.id) === i);
      }
      return result;
    },
    removeDuplicatesReducer(acc, slot) {
      const duplicate = acc.find(s => s.date === slot.date && s.time === slot.time);
      if (duplicate) {
        duplicate.seats_remaining = Math.min(duplicate.seats_remaining, slot.seats_remaining);
      } else {
        acc.push(slot);
      }
      return acc;
    },
    byDateAndTimeReducer(acc, slot) {
      const key = slot.date + ' ' + slot.time;
      (acc[key] = acc[key] || []).push(slot);
      return acc;
    },
    lowestNumberOfSeatsRemainingReducer(acc, slot) {
      const slotSeats = this.numberOrInfinity(slot.seats_remaining);
      const lowestSeats = this.numberOrInfinity(acc.seats_remaining);
      if (slotSeats < lowestSeats) {
        acc = slot;
      }
      return acc;
    },
    numberOrInfinity(number) {
      const seats = parseInt(number, 10);
      return isNaN(seats) ? Infinity : seats;
    }
  },
  computed: {
    selectedTypes() {
      return this.types.filter(t => this.selectedTypeIds.includes(t.id));
    },
    selectedInterviewers() {
      return this.interviewers.filter(t => this.selectedInterviewerIds.includes(t.id));
    },
    multiInterviewSelection() {
      return {
        interviewTypes: this.selectedTypes,
        interviewers: this.selectedInterviewers
      };
    },
    someSelectedTypesHaveNoSlots() {
      return this.selectedTypes.some(t => t.slots === undefined || t.slots.length === 0);
    },
    slots() {
      if (this.selectedTypeIds.length > 0) {
        const availableSlots = this.selectedTypes.flatMap(type => {
          if (type.slots === undefined) {
            return [];
          } else {
            return type.slots.reduce(this.removeDuplicatesReducer, []);
          }
        });

        const slotsByDateAndTime = availableSlots.reduce(this.byDateAndTimeReducer, {});

        const commonSlots = Object.values(slotsByDateAndTime).filter(slots => {
          return slots.length === this.selectedTypeIds.length;
        });

        return commonSlots.map(slots => {
          return slots.reduce(this.lowestNumberOfSeatsRemainingReducer);
        });
      }
    },
    locations() {
      return this.selectedTypes
        .map(t => {
          if (t.locations === undefined) {
            return [];
          }
          return t.locations.map(l => l.name);
        })
        .reduce((acc, locations, currentIndex) => {
          if (currentIndex === 0) {
            return locations;
          }
          return acc.filter((l) => locations.includes(l));
        }, []);
    },
    someSelectedTypesHaveNoLocations() {
      return this.selectedTypes.some(t => t.locations === undefined || t.locations.length == 0);
    },
    interviewers() {
      let user_identity_groups = this.selectedTypes.map(type => type.user_identities);
      let user_identities = this.intersection(user_identity_groups);
      user_identities = this.sort(user_identities);
      return this.unique(user_identities);
    },
    selectableInterviewerIds() {
      let availableSlots = this.selectedTypes.flatMap(t => t.slots || []);
      let filteredSlots = availableSlots.filter(s => this.dateAndTimeFor(s) === this.selectedSlot);
      let unselectableInterviewerIds = filteredSlots.flatMap(s => s.unavailable_ids);
      return this.interviewers.map(i => i.id).filter(id => !unselectableInterviewerIds.includes(id));
    },
    programs() {
      let programs = this.selectedTypes.flatMap(type => type.programs);
      programs = this.sort(programs);
      return this.unique(programs);
    }
  },
  created() {
    this.fetchTypes();
  },
  watch: {
    multiInterviewSelection: {
      handler(newValue, _oldValue) {
        this.$emit('update:modelValue', newValue);
      },
      deep: true
    },
    selectedTypes(newValue, _oldValue) {
      this.selectedSlot = null;
    },
    selectableInterviewerIds: {
      handler(newValue, _oldValue) {
        this.selectedInterviewerIds = this.selectedInterviewerIds.filter(id => newValue.includes(id));
      },
      deep: true,
    }
  },
}
</script>

<template>
   <div>
    <ul>
      <multi-interview-form-field id="interview_type_id" label="Interview Type">
        <multi-select-checkbox v-if="allTypes.length > 0" id="interview_type_id"
          :checkbox_name="name + '[type_ids][]'"
          title="Type"
          role_prefix="interview-types"
          :collection="allTypes"
          :selectableIds="selectableTypeIds"
          v-model:values="selectedTypeIds">
        </multi-select-checkbox>
        <span v-else>There are no interview types available.</span>
      </multi-interview-form-field>
      <multi-interview-form-field label="Date & Time" id="multi_interviews[slot]">
        <interview-slot-picker v-show="selectedTypes.length > 0 && slots.length > 0"
          :slots="slots"
          v-model="selectedSlot"
          :name="name"
          :min-seats="applicantIds.length">
        </interview-slot-picker>
        <template v-if="selectedTypes.length > 0">
          <div v-show="slots.length === 0">
            There are no common date and time combinations available for selected interviews.
          </div>
          <div v-show="someSelectedTypesHaveNoSlots">
            One or more interviews selected have no date and time slots set up.
          </div>
        </template>
      </multi-interview-form-field>
      <multi-interview-form-field id="location_id" label="Location">
        <location-select v-show="locations.length > 0" id="location_id"
                         :name="name + '[location]'"
                         :locations="locations" v-model="selectedLocation">
        </location-select>
        <template v-if="selectedTypes.length > 0">
          <div v-show="locations.length === 0">
            There are no common locations available for the selected interviews.
          </div>
          <div v-show="someSelectedTypesHaveNoLocations">
            One or more selected interviews have no locations set up.
          </div>
        </template>
      </multi-interview-form-field>
      <multi-interview-form-field id="interviewer_ids" label="Interviewers">
        <multi-select-checkbox v-if="interviewers.length > 0" id="multi-interview-interviewers-select"
          :checkbox_name="name + '[interviewer_ids][]'"
          role_prefix="interviewer"
          :title="'Name'"
          :collection="interviewers"
          :selectableIds="selectableInterviewerIds"
          v-model:values="selectedInterviewerIds">
        </multi-select-checkbox>
        <span v-else>Once one or more interview types are selected above,
        the list of potential interviewers will populate accordingly.</span>
      </multi-interview-form-field>
      <multi-interview-form-field id="interviewer_ids" label="Programs">
        <multi-select-checkbox v-if="programs.length > 0" id="multi-interview-program-select"
          role_prefix="multi-interview-program"
          :title="'Interviews will be scheduled for applicants with the following programs:'"
          :collection="programs"
          :selectable="false"
          v-model:values="selectedProgramIds">
        </multi-select-checkbox>
        <span v-else>Once one or more interview types are selected above,
        the program list will populate accordingly.</span>
      </multi-interview-form-field>
    </ul>
    <input type="hidden" id="hiddenApplicantId" name="multi_interview[applicant_ids]" :value="JSON.stringify(this.applicantIds)">
  </div>
</template>
