<script>
export default {
  name: 'multi-interview-page',
  props: {
    returnTo: String,
    source: String,
    multiInterview: Object,
    name: String,
    allTypes: Array,
    selectableTypeIds: Array
  },
  data() {
    return {
      state: 'Form',
      csrfParam: '',
      csrfToken: '',
      errorMessages: [],
      multiInterviewSelection: { interviewTypes: [], interviewers: [] },
      pairing: {}
    };
  },
  methods: {
    getMetaContent(name) {
      let meta = document.querySelector(`meta[name=${name}]`);
      return meta ? meta.content : '';
    },
    redirectTo(location) {
      window.location = location;
    },
    resetPairing() {
      this.state = 'Form';
      this.pairing = {};
    },
    hideModal() {
      $(this.$refs.pairing)
        .dialog('close')
        .dialog('destroy');
    },
    showModal() {
      $(this.$refs.pairing).dialog({
        modal: true,
        height: 400,
        width: 800,
        title: 'Assign Interviewer to Interview Type',
        close: () => this.resetPairing(),
        buttons: [
          {
            text: 'Cancel',
            class: "bordered_button",
            click: () => { this.hideModal(); }
          },
          {
            text: 'Save',
            class: "primary_button",
            click: () => { this.hideModal();this.save(); }
          }
        ]
      });
    },
    postFormAsJson(url) {
      let form = this.$refs.form;
      let data = new FormData(form);
      return this.$http.post(url, data)
        .then(response => response.data)
        .catch(() => this.errorMessages = ['A server error occurred, please try again']);
    },
    save() {
      this.postFormAsJson('/multi_interviews.json')
        .then(data => {
          this.errorMessages = data.errors;
          if (this.errorMessages.length > 0) {
            this.state = 'Form';
          } else {
            let params = 'success_message=' + encodeURIComponent(data.success_message) +
              '&return_to=' + encodeURIComponent(this.returnTo);

            this.redirectTo('/multi_interviews/success?' + params);
          }
        });
    },
    validate() {
      this.postFormAsJson('/multi_interviews/validate.json')
        .then(data => {
          this.errorMessages = data;
          if (this.errorMessages.length > 0) {
            this.state = 'Form';
          } else {
            this.state = 'Pairing';
            this.$nextTick(this.showModal);
          }
        });
    },
    updatePairing(event) {
      let interviewerId = parseInt(event.target.value, 10);
      if (interviewerId > 0) {
        this.pairing[event.target.dataset['id']] = interviewerId;
      } else {
        delete this.pairing[event.target.dataset['id']];
      }
    },
    availableInterviewersForInterviewType(interviewTypeId) {
      let selectedInterviewerIds = Object.values(this.pairing);
      return this.multiInterviewSelection.interviewers
        .filter(i => this.pairing[interviewTypeId] == i.id || !selectedInterviewerIds.includes(i.id));
    }
  },
  created() {
    this.csrfParam = this.getMetaContent('csrf-param');
    this.csrfToken = this.getMetaContent('csrf-token');
  },
  watch: {
    errorMessages: {
      handler(newValue, oldVal) {
        if (newValue && newValue.length > 0) {
          window.scrollTo(0, 0);
        }
      },
      deep: true
    },
  }
}
</script>

<template>
  <div>
    <error-explanation :error-messages="errorMessages" v-show="errorMessages.length > 0"></error-explanation>
    <form ref="form" accept-charset="UTF-8" action="/multi_interviews" class="new_multi_interview"
      id="multi_interview_form" method="post">
      <input name="utf8" type="hidden" value="&#x2713;"/>
      <input :name="csrfParam" type="hidden" :value="csrfToken"/>

      <multi-interview-form :name="name" :multi-interview="multiInterview" :source="source"
          :selectable-type-ids="selectableTypeIds" :all-types="allTypes" v-model="multiInterviewSelection">
      </multi-interview-form>

      <ul class="multi-interviews__buttons">
        <li><a class="button" :href="returnTo">Cancel</a></li>
        <li><a @click.prevent="validate" href="#" class="button with_icon">Next</a></li>
      </ul>

      <div ref="pairing" class="multi-interview-page-pairing" v-if="state === 'Pairing'">
        <ul>
          <multi-interview-form-field v-for="interviewType in multiInterviewSelection.interviewTypes"
            :id="'interview_select_' + interviewType.id" :key="interviewType.id" :label="interviewType.name">
            <select :name="'multi_interview[interviewers_by_interview_type_id][' + interviewType.id + ']'"
              :data-id="interviewType.id" :value="pairing[interviewType.id]" @change="updatePairing" :id="'interview_select_' + interviewType.id">
              <option></option>
              <option v-for="interviewer in availableInterviewersForInterviewType(interviewType.id)"
                :key="interviewer.id" :value="interviewer.id">{{interviewer.name}}</option>
            </select>
          </multi-interview-form-field>
        </ul>
      </div>
    </form>
  </div>
</template>
