<script>
export default {
  name: 'experience-row',
  props: {
    experience: Object,
    expanded: Boolean,
    index: Number,
    features: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['toggle'],
  computed: {
    checkAndDisplayDescription() {
      return this.experience.duties ? this.experience.duties : 'No descriptions are available';
    },
    checkAndSetBgClass() {
      return (this.index + 1) % 2 == 0 ? 'even-values' : 'odd-values';
    },
    sanitizeTotalHours() {
      return this.experience.total_hours == null ? 'N/A' : this.experience.total_hours;
    },
    meaningfulLabel() {
      return `Yes, Show meaningful details of experience ${this.experience.title} ` +
             `for type ${this.experience.experience_type_description}`;
    },
    descriptionLabel() {
      return `Show description of experience ${this.experience.title} ` +
             `for type ${this.experience.experience_type_description}`;
    },
    detailsLabel() {
      return `Show details of experience ${this.experience.title} ` +
             `for type ${this.experience.experience_type_description}`;
    }
  },
  methods: {
    formatDate(date) {
      if (!date) { return 'N/A'; }
      const format = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
      const formattedDate = new Date(date).toLocaleDateString('en-US', format);
      return formattedDate;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.features.handleCompetencies) {
        $(this.$refs.competenciesPopOver).initPopOver();
      }
    });
  }
}
</script>

<template>
  <tr class="no-alternate-bg" data-role="experience_row"
                 :data-exp-type-id="experience.experience_type_id"
                 :data-exp-meaningful="experience.most_meaningful">
    <td v-if="features.experienceInstances && experience.experience_instances.length > 1"
      @click.prevent="$emit('toggle', experience.id)"
      class="toggle_static"
      :class="{ opened: expanded }"
      data-role="experience-title">
      <a href="#" role="button"
         :aria-label="'Show all experience instances of ' + experience.title"
         class="experience_instance">{{experience.title || 'N/A'}}</a>
    </td>
    <td v-else data-role="experience-title">{{experience.title || 'N/A'}}</td>
    <td data-role="experience-experience_type_description">{{experience.experience_type_description || 'N/A'}}</td>
    <td data-role="experience-total_hours">{{sanitizeTotalHours}}</td>
    <td data-role="experience-start_date">{{formatDate(experience.start_date)}}</td>
    <td data-role="experience-end_date">{{formatDate(experience.end_date)}}</td>
    <td v-if='experience.most_meaningful' data-role="experience-most_meaningful">
      <div class='toggle_detail closed full-width'>
        <a class="action closed t-most-meaningful" role="button" href="#"
           :aria-label="meaningfulLabel"><div class="arrow mright3"></div>Yes</a>
        <div class='details hide'>
          <div class='details_info clearfix same-color'>
            {{experience.most_meaningful_description}}
          </div>
        </div>
      </div>
    </td>
    <td v-else data-role="experience-most_meaningful">No</td>
    <td data-role="experience-duties">
      <div class='toggle_detail closed full-width'>
        <a class="action closed t-description" role="button" href="#"
           :aria-label="descriptionLabel"><div class="arrow mright3"></div>Description</a>
        <div class='details hide'>
          <div class='details_info clearfix same-color'>
            {{checkAndDisplayDescription}}
          </div>
        </div>
      </div>
    </td>
    <td data-role="experience-details">
      <div class='toggle_detail closed full-width'>
        <a class="action closed t-details" role="button" href="#"
           :aria-label="detailsLabel"><div class="arrow mright3"></div>Details</a>
        <div class='details hide'>
          <div class='details_info clearfix same-color'>
            <experience-details :details="experience.details"></experience-details>
          </div>
        </div>
      </div>
    </td>
    <td v-if="features.handleCompetencies" ref="competenciesPopOver"
        class="toggle_competencies right pop_over closed"
        v-html="experience.competencies_matching"></td>
  </tr>
</template>
