<script>
export default {
  name: 'multi-interview-form-field',
  props: {
    id: String,
    label: String
  }
}
</script>

<template>
  <li class="multi-interview-form-field">
    <div class="field-label">
      <label class="label" :for="id">{{label}}</label>
    </div>
    <div class="field-body">
      <div class="field-body-wrapper">
        <slot></slot>
      </div>
    </div>
  </li>
</template>
