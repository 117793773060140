<script>
export default {
  name: "interview-slot-picker",
  props: {
    modelValue: String,
    name: String,
    minSeats: Number,
    slots: {
      type: Array
    }
  },
  emits: ['update:modelValue'],
  methods: {
    seatsOrNotApplicable(seatsRemaining) {
      const seats = parseInt(seatsRemaining, 10);
      return isNaN(seats) ? 'N/A' : seats;
    },
    doesNotHaveEnoughSeats(slot) {
      return slot.seats_remaining !== null && slot.seats_remaining < this.minSeats;
    },
    isChecked(slot) {
      return this.dateAndTimeFor(slot) === this.modelValue && !this.doesNotHaveEnoughSeats(slot);
    },
    dateAndTimeFor(slot) {
      return slot.date + ' ' + slot.time;
    }
  }
}
</script>

<template>
  <table data-role="interview-slot-picker">
    <thead>
      <tr>
        <th class='interview-slot-picker__radio-header hidden_header'>Slot Picker</th>
        <th>Date</th>
        <th>Time</th>
        <th>Seats Open</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="slot in slots" :key="slot.id" :class="{ 'interview-slot-picker__row--no-seats': doesNotHaveEnoughSeats(slot) }"
        :data-role="'interview-slot-picker-' + slot.id">
        <td>
          <input
            @change="$emit('update:modelValue', $event.target.value)"
            type="radio"
            aria-label="multi"
            :name="name + '[slot]'" :key="slot.id"
            :value="dateAndTimeFor(slot)"
            :checked="isChecked(slot)"
            :disabled="doesNotHaveEnoughSeats(slot)">
        </td>
        <td data-role="interview-slot-picker__date">{{slot.date}}</td>
        <td data-role="interview-slot-picker__time">{{slot.time}}</td>
        <td data-role="interview-slot-picker__seats-remaining">{{seatsOrNotApplicable(slot.seats_remaining)}}</td>
      </tr>
    </tbody>
  </table>
</template>
