<script>
export default {
  name: "error-explanation",
  props: {
    errorMessages: {
      type: Array,
      default: () => ([])
    }
  },
}
</script>

<template>
  <div class="errorExplanation" id="errorExplanation">
    <h2>{{ errorMessages.length }} {{ errorMessages.length !== 1 ? 'errors' : 'error' }} prohibited this form from being saved</h2>
    <ul>
      <li v-for="errorMessage in errorMessages">{{errorMessage}}</li>
    </ul>
  </div>
</template>
