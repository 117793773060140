<script>
export default {
  name: 'experience-list',
  props: {
    experiences: {
      type: Array,
      required: true
    },
    experienceTypes: {
      type: Array,
      required: true
    },
    features: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return { expanded: {}, deleteIconUrl: '/images/icons/delete-red-16x16.png' };
  },
  mounted() {
    this.$nextTick(() => {
      $(this.$refs.experienceTypesFilter).dropdown({
        forceSelection: false,
        showOnFocus: true,
        duration: 0,
        on: 'hover',
        onChange: function(value, text, $selectedItem) {
          $('input[data-role="experience-list-most_meaningful_filter"]').prop('checked', false);
          if(value.length > 0){
            $('tr[data-exp-type-id]').hide();
            for (var i = 0; i < value.length; ++i){
              $('tr[data-exp-type-id='+value[i]+']').show()
            }
          }else{
            $('tr[data-exp-type-id]').show();
          }
        },
        onAdd: function(addedValue, addedText, $addedChoice) {
          $('.j-exp-status').text(addedText + ' experience type added.');
          setTimeout(function(){
            var active = $('.j-exp-filter').closest('.experience-list-filter').find('.item.selected').not('.filtered').text();
            var search_val = 'Press enter to select.'
            if(active !== ""){
              $('.j-exp-filter').html(active + ' ' + search_val);
            }
          }, 200);
        },
        onRemove: function(removedValue, removedText, $removedChoice) {
          $('.j-exp-status').text(removedText + ' experience type removed.');
          setTimeout(function(){
            var current = $('.j-exp-filter').closest('.experience-list-filter');
            var active = current.find('.item.selected').not(".filtered").text();
            var search_val = 'Press enter to select.'
            if(active !== ""){
              $('.j-exp-filter').html(active + ' ' + search_val);
            }else if(current.find('.item').not('.filtered').length > 0 && current.find('.item.selected').not(".filtered").length == 0){
              if(current.find('.menu').hasClass('hidden')){
                $('.j-exp-filter').text('Please press the down arrow to expand the dropdown.');
              }else{
                $('.j-exp-filter').text('No experience type focussed by default. Please press the down arrow.');
              }
            }
          }, 200);
        },
        onShow: function(){
          $('.ui.fluid input.search').attr('readonly', 'true');
          var selected_filters = [];
          var current = $('.j-exp-status').closest('.experience-list-filter');
          current.find('a.ui.label.transition.visible').each(function(){
            selected_filters.push($(this).text());
          });
          if(selected_filters.length > 0){
            var selected_filters_text = ' Selected experience types: ' + selected_filters.join(', ');
          }else{
            var selected_filters_text = '';
          }
          $('.j-exp-status').text('Select experience type filter dropdown expanded.' + selected_filters_text);

          if(current.find('.item').not('.filtered').length > 0 && current.find('.item.selected').not(".filtered").length == 0){
            setTimeout(function(){$('.j-exp-filter').text('No experience type focussed by default. Please press the down arrow.')}, 200);
          }else if(current.find('.item').not('.filtered').length == 0){
            setTimeout(function(){$('.j-exp-filter').text('All experience types selected.')}, 200);
          }
        },
        onHide: function(){
          //Don't change class to j-exp-status
          $('.j-exp-filter').text('Select experience type filter dropdown collapsed.');
          $('.ui.fluid input.search').val('');
        }
      });
      $(document).on('keyup', '.ui.fluid.search', function(e){
        if(e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 9){
          $(this).find('input.search').attr('readonly', 'true');
          var active = $(this).find('.item.selected').not('.filtered').text();
          var search_val = 'Press enter to select.'
          if(active !== ""){
            $(this).find('input.search').val(active);
            $('.j-exp-filter').text(search_val);
          }else if((e.keyCode === 38 || e.keyCode === 40) && $(this).find('.item').not('.filtered').length == 0){
            $('.j-exp-filter').text('All experience types selected.');
          }
        }
        else if( e.keyCode === 37 || e.keyCode === 39 ){
          $(this).find('input.search').val('');
          if($(this).find('a.ui.label.active').length == 0 && $(this).find('a.ui.label').length > 0){
            var focussed_filter = $(this).find('a.ui.label:last').addClass('active').text();
          }else if($(this).find('a.ui.label.active').length > 0){
            var focussed_filter = $(this).find('a.ui.label.active').text();
          }
          if(focussed_filter){
            $('.j-exp-filter').text('Press delete to remove the experience type ' + focussed_filter);
          }
        }
        else if( e.keyCode === 8 ){
          if($(this).find('input.search').val() !== ''){
            $(this).find('input.search').val('');
            $(this).find('input.search').trigger(
              jQuery.Event( 'keydown', { keyCode: 8, which: 8 } )
            );
          }
        }
      })
      $(document).on('click', '.experience_instance', function(){
        if($(this).closest('td').hasClass( "opened")){
          $(this).attr({ 'aria-label': $(this).attr('aria-label').replace('Hide', 'Show') });
        }
        else{
          $(this).attr({ 'aria-label': $(this).attr('aria-label').replace('Show', 'Hide') });
        }
      })
      $('#experiences_comprehensive_panels').css('overflow', 'initial');
      var input_label = 'This is a multiselect input dropdown field. '
                        + 'Please use up/down keys to find the experience type and '
                        + 'press enter to select an experience type. '
                        + 'Use left/right arrow keys to select any filtered experience type and press delete to remove it. '
                        + 'Press backspace to remove the last selected experience type.';
      $('#experiences_comprehensive_panels').find('.ui.fluid.search input.search').attr('aria-label', input_label);
    });
  },
  computed: {
    hasAnyExperienceInstances() {
      return this.experiences.find(this.hasExperienceInstances);
    },
    clearlabel() {
      return 'Clear filters to show all experiences'
    }
  },
  methods: {
    hasExperienceInstances(experience) {
      return experience.experience_instances.length;
    },
    toggle(experienceId) {
      this.expanded[experienceId] = !this.expanded[experienceId]
    },
    expandAll() {
      $(this.$refs.experienceTypesFilter).dropdown('clear');
      $('input[data-role="experience-list-most_meaningful_filter"]').prop('checked', false);
      $('tr[data-exp-type-id]').show();
      this.expanded = this.experiences.reduce((acc, experience) => {
        if (experience.experience_instances.length > 1) {
          acc[experience.id] = true;
        }
        return acc;
      }, {});
    },
    removeExpanded() {
      this.expanded = {};
    },
    collapseAll() {
      $(this.$refs.experienceTypesFilter).dropdown('clear');
      $('input[data-role="experience-list-most_meaningful_filter"]').prop('checked', false);
      $('tr[data-exp-type-id]').show();
      this.expanded = {};
    },
    clearExperienceTypesFilter() {
      $(this.$refs.experienceTypesFilter).dropdown('clear');
      this.expanded = {};
      $('tr[data-exp-type-id]').show();
      if($('input[data-role="experience-list-most_meaningful_filter"]').prop('checked') == true){
        $('#experience-filter-status').text('filters cleared');
      }
      $('input[data-role="experience-list-most_meaningful_filter"]').prop('checked', false);
    },
    checkMostMeaningful: function(event){
      $(this.$refs.experienceTypesFilter).dropdown('clear');
      this.expanded = {};
      $('#experience-filter-status').text('Experience table updated');
      if(event.target.checked){
        $('tr[data-exp-type-id]').hide();
        $('tr[data-exp-meaningful=true]').show();
      }else{
        $('tr[data-exp-type-id]').show();
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="experience-list-toolbar" v-if="features.experienceInstances">
      <div class="experience-list-field buttons" v-if="hasAnyExperienceInstances">
        <a data-role="experience-list-expand_all" href="#" @click.prevent="expandAll" tabindex="-1" aria-hidden="true">Expand All</a>
        <a data-role="experience-list-collapse_all" href="#" @click.prevent="collapseAll" tabindex="-1" aria-hidden="true">Collapse All</a>
      </div>
      <div class="experience-list-field experience-list-filter" v-on:click="removeExpanded">
        <div class="experience-list-field-options">
          <div class="screen-reader-only j-exp-status" role="status"></div>
          <div class="screen-reader-only j-exp-filter" role="status"></div>
          <label>Limit experience types to include only:</label>
          <div class="experience-list-clear-container">
              <a class="experience-list-clear-link" href="#" @click.prevent="clearExperienceTypesFilter" role="button">
                  <img :src="deleteIconUrl" :alt="clearlabel"/>
                  <span>Clear</span>
              </a>
          </div>
        </div>
        <select data-role="experience-list-type_filter" ref="experienceTypesFilter" class="ui fluid search selection dropdown"
                multiple>
          <option v-for="experienceType in experienceTypes" :value="experienceType.id">{{experienceType.name}}</option>
        </select>
      </div>
      <div class="experience-list-field">
        <label>
            <input data-role="experience-list-most_meaningful_filter" type="checkbox" v-on:click="checkMostMeaningful">
            Show only the most meaningful experiences
        </label>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th>Experience Name</th>
          <th>Experience Type</th>
          <th>Total Completed Hours</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Most Meaningful</th>
          <th><span class='pl-10 pr-10'>Description</span></th>
          <th><span class='pl-10 pr-10'>Details</span></th>
          <th v-if="features.handleCompetencies">Competencies</th>
        </tr>
      </thead>
      <tbody class="experience-list-body">
        <tr class='hide'>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td v-if="features.handleCompetencies"></td>
        </tr>
        <template v-for="(experience, index) in experiences" :key="experience.id">
          <experience-row @toggle="toggle" :experience="experience" :index="index" :features="features"
            :expanded="expanded[experience.id]">
          </experience-row>
          <template v-if="features.experienceInstances && expanded[experience.id]">
            <experience-instance-row v-for="experienceInstance in experience.experience_instances"
              :experience-instance="experienceInstance" :features="features" :key="experienceInstance.id">
            </experience-instance-row>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>
