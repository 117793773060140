<script>
export default {
  name: 'location-select',
  props: {
    locations: {
      type: Array
    },
    modelValue: String
  },
  emits: ['update:modelValue'],
  data() {
    return {
      selected: this.modelValue
    }
  }
}
</script>

<template>
  <select v-model="selected" @change="$emit('update:modelValue', $event.target.value)" class="location-select">
    <option></option>
    <option v-for="location in locations" :selected="location === selected" :value="location">
      {{location}}
    </option>
  </select>
</template>
