<script>
export default {
  name: 'collection-select',
  props: {
    modelValue: [String, Number],
    collection: {
      type: Array
    },
    valueProperty: {
      type: String,
      default: 'id'
    },
    textProperty: {
      type: String,
      default: 'name'
    },
    includeBlank: Boolean
  },
  emits: ['update:modelValue']
}
</script>

<template>
  <select
    @change="$emit('update:modelValue', parseInt($event.target.value, 10))"
    :modelValue="modelValue">
    <option value='' v-if="includeBlank"></option>
    <option v-for="element in collection" :value="element[valueProperty]" :selected="element[valueProperty] === modelValue">
      {{element[textProperty]}}
    </option>
  </select>
</template>
