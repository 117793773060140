<script>
  export default {
    name: "email-attachments-preview",
    props: {
      preview: Object,
      inputName: String
    },
    emits: ['remove-file'],
    data() {
      return { deleteIconUrl: '/images/icons/delete-red-16x16.png' };
    },
    computed: {
      progressRounded() {
        return Math.round(this.preview.progress);
      },
      progressBarStyle() {
        return {
          width: this.preview.progress + '%'
        };
      },
      inputNameAttr() {
        const duplicatedSuffix = '_to_duplicate';
        const propName = 'email_attachment_ids';
        const paramName = this.preview.toBeDuplicated ? propName + duplicatedSuffix : propName;

        return this.inputName + "[" + paramName + "][]";
      },
      readableFileSize() {
        let size = this.preview.fileSize;
        let selectedSize = 0;
        let selectedUnit = "b";

        if (size > 0) {
          const units = ['tb', 'gb', 'mb', 'kb', 'b'];

          for (let i = 0; i < units.length; i++) {
            const unit = units[i];
            const cutoff = Math.pow(1000, 4 - i) / 10;

            if (size >= cutoff) {
              selectedSize = size / Math.pow(1000, 4 - i);
              selectedUnit = unit;
              break;
            }
          }

          selectedSize = Math.round(10 * selectedSize) / 10;
          const dictFileSizeUnits = {
            tb: "TB",
            gb: "GB",
            mb: "MB",
            kb: "KB",
            b: "b"
          };
          return selectedSize + ' ' + dictFileSizeUnits[selectedUnit];
        }
      }
    },
    methods: {
      deleteFile() {
        this.$emit('remove-file', this.preview.uuid);
      }
    },
  }
</script>

<template>
  <div v-if="preview.uuid" class="file-row" tabindex='0'>
    <input v-if="preview.id" type="hidden" :name="inputNameAttr" :value="preview.id" />
    <div>
      <a v-if="preview.url" target="_blank" :href="preview.url">{{ preview.fileName }}</a>
      <span v-else data-role="file-name">{{ preview.fileName }}</span>
      <span v-show="preview.fileSize" data-role="file-size">
        ({{ readableFileSize }})
      </span>
       <div class="right-block">
        <span @click="deleteFile" class="tipsy-bottom" title="Delete Attachment" role="button" href='#' tabindex='0'>
          <img class="delete" :aria-label="'Delete attachment ' + preview.fileName" alt="Delete file" :src="deleteIconUrl">
        </span>
      </div>
      <strong class="progress-bar-text" v-if="preview.progress < 100">{{ progressRounded }}%</strong>
      <strong class="progress-bar-text" v-else-if="preview.progress >= 100">
          Finalizing...
      </strong>
    </div>
    <div v-if="preview.errorMessage" class="error">
      <span>Error: {{ preview.errorMessage }}</span>
    </div>
    <div v-if="preview.progress">
      <div class="progress active">
        <div class="progress-bar" :style="progressBarStyle"></div>
      </div>
    </div>
  </div>
</template>
