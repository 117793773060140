<script>
export default {
  name: "experience-instance-row",
  props: {
    experienceInstance: Object,
    features: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    lastCellColspan() {
      return this.features.handleCompetencies ? 4 : 3;
    },
  },
  methods: {
    formatDate(date) {
      if (!date) { return 'N/A'; }
      const format = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' };
      const formattedDate = new Date(date).toLocaleDateString('en-US', format);
      return formattedDate;
    }
  }
}
</script>

<template>
  <tr class="no-alternate-bg" data-role="experience_instance_row">
    <td colspan="2"></td>
    <td data-role="experience_instance-total_hours">{{experienceInstance.total_hours}}</td>
    <td data-role="experience_instance-start_date">{{formatDate(experienceInstance.start_date)}}</td>
    <td data-role="experience_instance-end_date">{{formatDate(experienceInstance.end_date)}}</td>
    <td :colspan="lastCellColspan"></td>
  </tr>
</template>
