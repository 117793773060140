<script>
export default {
  name: 'experience-details',
  props: {
    details: Array
  },
  computed: {
    checkDetails() {
      return this.details && this.details.length > 0
    }
  }
}
</script>

<template>
  <div v-if="checkDetails">
    <table class="experience_details">
      <tbody>
        <tr v-for="(detail, index) in details" class="no-alternate-bg" :data-role="'experience_detail-' + index">
          <td data-role="experience_detail-label" class="label">{{detail[0]}}</td>
          <td data-role="experience_detail-value">{{detail[1]}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else>No additional details are available</div>
</template>
