/**
 * This component provides a button that displays a drop down panel when
 * click. It requires a label and can have a blank message that is
 * displayed when the slot content is empty.
 */
 <script>
  export default {
    name: "dropdown-button",
  props: {
    /**
     * Button label
     */
    label: {
      type: String,
      required: true
    },

    /**
     * Message displayed when the slot content is empty. Nothing will be display if this props is omitted.
     */
    blankMessage: String
  },
  data() {
    return {
      expanded: false
    };
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    }
  },
  computed: {
    shouldDisplayBlankMessage() {
      const hasContent = vnode => {
        if (vnode.type === Vue.Comment) return false;

        if (Array.isArray(vnode.children) && !vnode.children.length) return false;

        return (
          vnode.type !== Vue.Text
          || (typeof vnode.children === 'string' && vnode.children.trim() !== '')
        );
      }

      const hasDefaultSlotContent = (
        typeof this.$slots.default === 'function' &&
        this.$slots.default() &&
        this.$slots.default().some(hasContent)
      )

      const hasBlankMessage = this.blankMessage && this.blankMessage.length > 0;

      return !hasDefaultSlotContent && hasBlankMessage;
    }
  }
}
</script>

<template>
  <div @click.prevent="toggle" class="dropdown-button" :class="{ opened: expanded }">
    <div class="arrow"></div>
    <a class="dropdown-trigger" href="#">{{label}}</a>
    <div class="dropdown-container" v-show="expanded">
      <div class="dropdown-content clearfix">
        <slot></slot>
        <p class="blank_pane" v-if="shouldDisplayBlankMessage">{{blankMessage}}</p>
      </div>
    </div>
  </div>
</template>

