<template>
  <span id="vueVersion">VueJS v{{ version }}</span>
</template>

<script>
  export default {
    data() {
      return {
        version: Vue.version
      };
    }
  }
</script>
