<script>
export default {
  name: 'multi-select-checkbox',
  props: {
    collection: {
      type: Array,
      default: () => ([])
    },
    values: {
      type: Array,
      default: () => ([])
    },
    selectableIds: {
      type: Array,
      default: () => ([])
    },
    selectable: {
      type: Boolean,
      default: () => (true)
    },
    title: String,
    role_prefix: String,
    checkbox_name: String,
    checkbox_label: {
      type: String,
      default: () => ('')
    }
  },
  emits: ['update:values'],
  methods: {
    unselectable: function (typeId) {
      return !this.selectableIds.includes(typeId);
    },
    role(item) {
      return 'multi-select-checkbox-' + this.role_prefix + '-' + item.id;
    },
    onCheck(event) {
      let selectedId = parseInt(event.target.value, 10);
      if (event.target.checked) {
        this.$emit('update:values', this.values.concat(selectedId));
      } else {
        this.$emit('update:values', this.values.filter(id => id !== selectedId));
      }
    },
    onSelectAll(event) {
      this.$emit('update:values', event.target.checked ? [...this.selectableIds] : []);
    }
  },
  computed: {
    areAllSelected() {
      return this.values && this.values.length > 0 && this.values.length === this.selectableIds.length;
    },
    setUserCheckboxLabel() {
      let label = ''
      if (this.checkbox_label == 'interviewers'){
        label = 'Select interviewer '
      }else if (this.checkbox_label == 'reviewers'){
        label = 'Select reviewer '
      }
      return label
    }
  },
}
</script>

<template>
  <div class="multi-select-checkbox" style="z-index: 99">
    <table>
      <thead>
        <tr>
          <th class="select-all">
            <fieldset>
              <legend class="fieldset-legend" title="Select All"/>
              <input v-if="selectable" type="checkbox" :checked="areAllSelected" @change="onSelectAll" name="Select All" class="select_all" :aria-label="'Select all ' + checkbox_label">
            </fieldset>
          </th>
          <th>{{title}}</th>
        </tr>
      </thead>
      <tr v-for="item in collection" :class="{disabled: unselectable(item.id)}" :data-role="role(item)">
        <td>
          <fieldset>
            <legend class="fieldset-legend" title="Multi select checkbox"/>
            <input v-if="selectable"
              type="checkbox"
              :data-role="role(item) + '--checkbox'"
              :name="checkbox_name"
              :value="item.id"
              :aria-label="setUserCheckboxLabel + item.name"
              :disabled="unselectable(item.id)"
              :checked="values.includes(item.id)"
              @change="onCheck">
          </fieldset>
        </td>
        <td>
          {{item.name}}
        </td>
      </tr>
    </table>
  </div>
</template>
